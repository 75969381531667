import React from 'react';
import Pagetitle from '../elements/Pagetitle';
import Timeline from '../elements/Timeline';

const educationData = [
  {
    id: 1,
    title: 'Acamedic Degree',
    years: '2019 - Present',
    content:
      'Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.',
  },
];

const experienceData = [
  {
    id: 1,
    title: 'Web Designer',
    years: '2019 - Present',
    content:
      'Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.',
  },
  {
    id: 2,
    title: 'Front-End Developer',
    years: '2017 - 2013',
    content:
      'Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.',
  },
  {
    id: 3,
    title: 'Back-End Developer',
    years: '2013 - 2009',
    content:
      'Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.',
  },
];

function Experiences() {
  return (
    <section id='experience'>
      <div className='container'>
        <Pagetitle title='Education' />
        <div className='row'>
          <div className='col-md-9'>
            <div className='timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden'>
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className='line'></span>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='spacer d-md-none d-lg-none' data-height='30'></div>
            {/* <div className='timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden'>
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className='line'></span>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
